<!-- 居民点单点单类型表格 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      @delect="del"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
    </ax-table>
    <!-- 新增点单类型弹窗 -->
    <add-orderType ref="addOrderType" @refsh="refsh"></add-orderType>
  </div>
</template>

<script>
import addOrderType from "./addOrderType.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "typeName",
    options: { placeholder: "点单类型" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addOrderType },
  data() {
    //这里存放数据
    return {
      api,
      // 表格模块数据
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "分组名称",
            dataIndex: "parentTypeName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "点单类型",
            dataIndex: "typeName",
            ellipsis: true,
            width: 100,
          },
          {
            title: "参考分值",
            dataIndex: "referScore",
            ellipsis: false,
            width: 150,
          },
          {
            title: "创建人",
            dataIndex: "createBy",
            ellipsis: false,
            width: 150,
          },
          {
            title: "创建时间",
            dataIndex: "createTime",
            ellipsis: false,
            width: 150,
          },
        ],
        true, //去掉序号
        {
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#2A5CFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "确定删除该活动吗？",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [
        { name: "add", text: "新增" },
        { name: "delect", text: "批量删除" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.getTypeList,
      dataSourceParams: { grade: 1 },
      // 级联选择器模块的数据
      options: [], //级联选择器数据源
      gridIds: [], // 模糊查询传值
      // gridId: "",
      gridIds1: [], //级联选择器选中值数组
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
          record.title = "编辑点单类型";
          this.$refs.addOrderType.openModal(record);
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteTypeById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    add() {
      this.$refs.addOrderType.openModal({ title: "新增点单类型" });
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteTypeByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.blue {
  background-color: rgba(42, 92, 255, 0.2);
  color: #2a5cff;
  border: rgba(42, 92, 255, 0.2);
  border-radius: 4px;
}
.orange {
  background-color: #fff8f0;
  color: #e6a23c;
  border: #fff8f0;
  border-radius: 4px;
}
.green {
  background-color: #ebf9e6;
  color: #67c23a;
  border-radius: 4px;
  border: #ebf9e6;
}
.gray {
  background-color: #e6e9ee;
  color: #2d3138;
  border-radius: 4px;
  border: #e6e9ee;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>