<!-- 点单类型  -->
<template>
  <div class="">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="点单类型">
        <order-type></order-type>
      </a-tab-pane>
      <a-tab-pane key="2" tab="分组管理">
        <group-management key="2"></group-management>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import orderType from "./orderType.vue";
import groupManagement from "./groupManagement.vue";
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { orderType,groupManagement},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-tabs .ant-tabs-tab-active {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
/deep/.ant-tabs .ant-tabs-tab:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
/deep/.ant-tabs .ant-tabs-ink-bar {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
</style>